import * as d3 from "d3";
import {useEffect, useMemo, useRef} from "react";
import admin1Shapes from "./admin-1.json";
import countryShapes from "./countries.json";
import {airportToBoundsFeature, featuresToFeatureCollection, geometryToFeature, pathsToGeometries} from './geometry';
import {centerLongitude, pathsToCenterPoints} from "./math";
import * as styles from './PathsMap.module.less';
import useChartDimensions from "./useChartDimensions.js";

const scale = value => value * window.devicePixelRatio;

const HORIZONTAL_PADDING = 20;
const VERTICAL_PADDING = 10;

export default function PathsMap({data}) {
    const [ref, dimensions] = useChartDimensions();

    // Use a fixed ratio for the chart height.
    const height = dimensions.width * 0.5;

    return (
        <div className={styles.container} ref={ref}>
            <PathsMapContent width={dimensions.width} height={height} data={data}/>
        </div>
    );
}

function drawMap(context, width, height, scale, projection, pathsGeometries, airports) {
    const path = d3.geoPath(projection, context);

    const showLabelsBreakpoint = scale(500);
    const markerCircleScale = d3.scaleLinear([scale(400), scale(1000)], [scale(1.5), scale(3)]).clamp(true);
    const pathWidthScale = d3.scaleLinear([scale(400), scale(1000)], [scale(1), scale(2)]).clamp(true);

    const markerCircleRadius = markerCircleScale(width);
    const pathStrokeWidth = pathWidthScale(width);

    context.clearRect(0, 0, width, height);

    context.font = `${markerCircleRadius * 3.5}px sans-serif`;

    const sphere = {type: "Sphere"};
    context.beginPath();
    context.fillStyle = '#111315';
    path(sphere);
    context.fill();

    context.beginPath();
    path(d3.geoGraticule10());
    context.strokeStyle = '#2F3034';
    context.lineWidth = scale(0.5);
    context.stroke();

    context.beginPath();
    path(countryShapes);
    context.fillStyle = '#323337';
    context.fill();
    context.strokeStyle = '#000000';
    context.lineWidth = scale(0.5);
    context.stroke();

    context.beginPath();
    path(admin1Shapes);
    context.strokeStyle = '#000000';
    context.lineWidth = scale(0.3);
    context.stroke();

    pathsGeometries.forEach(arc => {
        context.beginPath();
        path(arc);
        context.strokeStyle = '#3388FF';
        context.lineWidth = pathStrokeWidth;
        context.stroke();
    });

    airports.forEach(airport => {
        const center = projection([airport.longitude, airport.latitude]);

        context.beginPath();
        context.arc(center[0], center[1], markerCircleRadius, 0, 2 * Math.PI);
        context.strokeStyle = airport.style === 'dimmed' ? '#555555' : '#F6531A';
        context.lineWidth = scale(1);
        context.stroke();

        const showLabel = airport.withLabel && width >= showLabelsBreakpoint;
        context.fillStyle = airport.style === 'dimmed' ? '#555555' : '#c7c7c7';
        if (showLabel) {
            context.fillText(
                airport.iataCode,
                center[0] + markerCircleRadius * 2,
                center[1] + markerCircleRadius * 3
            );
        }
    });
}

function PathsMapContent({width: containerWidth, height: containerHeight, data}) {
    const canvasRef = useRef(null);

    const width = Math.floor(scale(containerWidth));
    const height = Math.floor(scale(containerHeight));

    const paths = data.paths;
    const airportIndex = data.airports;
    const airports = useMemo(() => {
        return Object.entries(airportIndex).map(([, airport]) => airport)
    }, [airportIndex]);

    const pathsGeometries = useMemo(() => {
        return pathsToGeometries(paths, airportIndex);
    }, [paths, airportIndex]);

    const projection = useMemo(() => {
        const features = airports.map(airportToBoundsFeature)
            .concat(pathsGeometries.map(geometryToFeature));

        let geo;
        if (features.length > 0) {
            geo = featuresToFeatureCollection(features);
        } else {
            geo = {type: "Sphere"};
        }

        const pointsForRotation = paths.length > 0
            ? pathsToCenterPoints(paths, airportIndex)
            : airports;
        const rotationLongitude = centerLongitude(pointsForRotation);

        const projection = d3.geoMercator().precision(0.2);
        projection.rotate([-rotationLongitude, 0]);
        projection.fitExtent([
            [scale(HORIZONTAL_PADDING), scale(VERTICAL_PADDING)],
            [width - scale(HORIZONTAL_PADDING), height - scale(VERTICAL_PADDING)]
        ], geo);

        return projection;
    }, [width, height, airportIndex, airports, paths, pathsGeometries]);

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        drawMap(context, width, height, scale, projection, pathsGeometries, airports);

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                drawMap(context, width, height, scale, projection, pathsGeometries, airports);
            }
        };
        window.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [width, height, projection, pathsGeometries, airports]);

    return <canvas ref={canvasRef} width={width} height={height} style={{
        width: `${containerWidth}px`,
        height: `${containerHeight}px`
    }}/>;
}