import {Airports, Path, Paths, Point} from "./types";
import * as d3 from "d3";

export function pathsToCenterPoints(paths: Paths, airports: Airports): Point[] {
    const result = paths.map(path => pathToCenterPoints(path, airports));
    return result.reduce((all, points) => all.concat(points), []);
}

export function pathToCenterPoints(path: Path, airports: Airports): Point[] {
    const points = [];
    for (let i = 0; i < path.length - 1; i++) {
        const origin = airports[path[i]];
        const destination = airports[path[i + 1]];
        const interpolator = d3.geoInterpolate(
            [origin.longitude, origin.latitude],
            [destination.longitude, destination.latitude]
        );
        const point = interpolator(0.5);
        points.push({
            longitude: point[0],
            latitude: point[1],
        });
    }

    return points;
}

export function centerLongitude(points: Point[]): number {
    if (points.length === 0) {
        return 0;
    }

    const longitudes = points.map(p => p.longitude)
        .sort((a, b) => a - b);

    let bestVariant = 0;
    let bestWidth = longitudes[longitudes.length - 1] - longitudes[0];

    for (let i = 0; i < points.length - 1; i++) {
        longitudes[i] += 360;

        const width = longitudes[i] - longitudes[i + 1];

        if (width < bestWidth) {
            bestVariant = i + 1;
            bestWidth = width;
        }
    }

    for (let i = 0; i < points.length; i++) {
        longitudes[i] = normalise(longitudes[i]);
    }

    for (let i = 0; i < bestVariant; i++) {
        longitudes[i] += 360;
    }

    longitudes.sort((a, b) => a - b);

    const center = (longitudes[longitudes.length - 1] - longitudes[0]) / 2;

    return normalise(longitudes[0] + center);
}

function normalise(degrees: number) {
    if (degrees > 180) {
        return degrees - 360;
    } else if (degrees < -180) {
        return degrees + 360;
    } else {
        return degrees;
    }
}
