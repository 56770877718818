import {Airport, Airports, Feature, FeatureCollection, Geometry, Path, Paths} from "./types";
import * as d3 from "d3";

export function pathsToGeometries(paths: Paths, airports: Airports): Geometry[] {
    return paths.map(path => pathToGeometry(path, airports));
}

function pathToGeometry(path: Path, airports: Airports): Geometry {
    return {
        type: "LineString",
        coordinates: path.map(airportId => {
            const airport = airports[airportId];
            return [airport.longitude, airport.latitude];
        })
    };
}

export function geometryToFeature(geometry: Geometry): Feature {
    return {
        type: 'Feature',
        geometry
    };
}

export function airportToBoundsFeature(airport: Airport): Feature {
    return {
        type: 'Feature',
        geometry: d3.geoCircle().center([airport.longitude, airport.latitude]).radius(3).precision(10)()
    };
}

export function featuresToFeatureCollection(features: Feature[]): FeatureCollection {
    return {
        type: 'FeatureCollection',
        features
    };
}