import {createRoot} from "react-dom/client";


export default function renderBrowserNavigation() {
    const rootElement = document.getElementById('browser-navigation');
    if (!rootElement) {
        return;
    }

    const root = createRoot(rootElement);
    root.render(<BrowserNavigation/>);
}

function BrowserNavigation() {
    const handleBackClick = () => {
        history.back();
    };
    const handleReloadClick = () => {
        window.location.reload();
    };
    const handleForwardClick = () => {
        history.forward();
    };

    const backButtonDisabled = history.length <= 1;

    return (
        <>
            <button type="button" className="btn btn-default" disabled={backButtonDisabled} onClick={handleBackClick}>
                <span className="fa fa-chevron-left"></span>
            </button>
            <button type="button" className="btn btn-default" onClick={handleReloadClick}>
                <span className="fa fa-refresh"></span>
            </button>
            <button type="button" className="btn btn-default" onClick={handleForwardClick}>
                <span className="fa fa-chevron-right"></span>
            </button>
        </>
    );
}